exports.components = {
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/Home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-home-sections-s-01-header-tsx": () => import("./../../../src/pages/Home/sections/S01_Header.tsx" /* webpackChunkName: "component---src-pages-home-sections-s-01-header-tsx" */),
  "component---src-pages-home-sections-s-02-features-tsx": () => import("./../../../src/pages/Home/sections/S02_Features.tsx" /* webpackChunkName: "component---src-pages-home-sections-s-02-features-tsx" */),
  "component---src-pages-home-sections-s-03-checkout-video-chat-tsx": () => import("./../../../src/pages/Home/sections/S03_CheckoutVideoChat.tsx" /* webpackChunkName: "component---src-pages-home-sections-s-03-checkout-video-chat-tsx" */),
  "component---src-pages-home-sections-s-04-thats-it-tsx": () => import("./../../../src/pages/Home/sections/S04_ThatsIt.tsx" /* webpackChunkName: "component---src-pages-home-sections-s-04-thats-it-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-tsx" */)
}

